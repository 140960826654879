import React from "react";
import { useI18n } from "react-simple-i18n";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";

import { GlobalSpacing, SectionGap, SectionSpacing } from "../../components/layout/common";

const features = [
  {
    title: "Cross-chain Accessibility",
    description: "Access the same CLIP tokens across different blockchains. Users can choose their preferred blockchain network for transactions.",
  },
  {
    title: "Enhanced Liquidity",
    description: "Multi-chain deployment enables trading on more exchanges and DeFi protocols, increasing overall market liquidity.",
  },
  {
    title: "Transaction Optimization",
    description: "Leverage the unique advantages of each blockchain. Choose the optimal network based on factors such as gas fees and transaction speed.",
  },
]

const MultiTokenInfo = () => {
	const { t } = useI18n();

	return (
		<GlobalSpacing>
			<SectionSpacing>
				<PreSaleInfoWrapper>
					<Typography variant="h2" textAlign="center">
						{t("body.multitoken.title")}
					</Typography>

					<Grid container spacing={{ xs: 2, sm: 3, md: 5 }}>
						{features.map((item: { title: string, description: string },  key: number) => (
							<Grid item xs={12} md={6} xl={4} key={key}>
								<PreSaleItem>
									<Typography variant="h3">
										{item.title}
									</Typography>

                  <Typography variant="body2" mt={4}>
                    {item.description}
                  </Typography>
								</PreSaleItem>
							</Grid>
						))}
					</Grid>
				</PreSaleInfoWrapper>
			</SectionSpacing>
		</GlobalSpacing>
	)
}

const PreSaleInfoWrapper = styled(SectionGap)(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	zIndex: 1,

	h2: {
		fontWeight: 800,
	}
}))

const PreSaleItem = styled('div')(({ theme }) => ({
	position: "relative",
	background: theme.palette.common.mainBg,
	backgroundClip: "padding-box",
	border: `solid 1px transparent`,
	padding: "40px 20px",
	borderRadius: '15px',
	userSelect: "none",
	cursor: "pointer",

	'&:before': {
		content: "''",
		position: "absolute",
		top: 0, right: 0, bottom: 0, left: 0,
		background: theme.palette.common.preSaleBorder,
		borderRadius: "inherit",
		margin: '-1px',
		zIndex: -1,
	},

	h3: {
		fontWeight: 700,
		textAlign: "center",
		color: theme.palette.common.typograph3,
	},

	[theme.breakpoints.down("sm")]: {
		padding: "30px 20px",
	}
}))

export { MultiTokenInfo };