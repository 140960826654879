
import { Typography, styled } from "@mui/material"
import React from "react"
import { GlobalSpacing, SectionSpacing } from "../../components/layout/common"
import { useI18n } from "react-simple-i18n"
import { azbitImg } from "../../assets/image"

export const PartnersSection = () =>{
  const { t } = useI18n();

  return (
    <GlobalSpacing>
			<SectionSpacing>
          <Typography variant="h2" textAlign="center">
						{t("body.partners.title")}
					</Typography>

          <ImageWrapper>
            <img
              src={azbitImg}
              alt="Azbit Logo"
            />
          </ImageWrapper>
      </SectionSpacing>
    </GlobalSpacing>
  )
}

const ImageWrapper = styled("div")(({ theme }) => ({
  margin: "auto",
	marginTop: "32px",
}))
